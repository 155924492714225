import React, { createContext, useContext, useState } from 'react';


const VideoContext = createContext();

export function useVideo() {
    return useContext(VideoContext);
}

export function VideoProvider({ children }) {
    const [isMuted, setIsMuted] = useState(true);

    const toggleMute = () => {
        setIsMuted(!isMuted);
    };

    return (
        <VideoContext.Provider value={{ isMuted, toggleMute }}>
            {children}
        </VideoContext.Provider>
    );
}
