import React from 'react';
import styles from './header.module.scss';
import love from '../../assets/mainPage/love.svg';
import list from '../../assets/mainPage/script.svg';
import settings from '../../assets/mainPage/gear.svg';
import flash from '../../assets/mainPage/thunder.svg';
import blades from '../../assets/mainPage/XMLID_118_.svg';
import { NavLink } from "react-router-dom";


export default function Header() {
    return (
        <div className=" sm:w-2/3 lg:w-2/5 2xl:mt-xl">
            <h1 className={styles.title + ' ' + "text-intenseBlue underline-offset-7  text-header my-2  md:my-2 text-xl md:text-2xl  2xl:text-4xl md:mt-0 font-aviano"}>RESOURCES</h1>
            <p className="my-2 text-white text-xxs md:text-base">Welcome Metalancer. You've taken the first step towards fulfilling the prophecy. Everything you need to defeat the centralisers can be found in this guide. Read it once, then read it again. Learn it. Embrace it. Share it with those who are worthy.</p>
            <div className="mt-2 lg:mt-4 text-white">
                <NavLink to="/sneak-peeks">
                    <div className={styles.shadoww + " w-full border border-intenseBlue text-xs md:text-lg font-bold bg-dark2 flex items-center mb-4 rounded-sm"}>
                        <div className={styles.utility + " w-10 md:w-16 pl-2 md:mr-1"}><img className="mx-auto" src={love} alt="Sneak Peeks" /></div>
                        <p className={styles.myBefore + ' w-full'}>
                            SNEAK PEEKS


                        </p>
                    </div>
                </NavLink>
                <NavLink to="/our-story">
                    <div className={styles.shadoww + " w-full border border-intenseBlue text-xs md:text-lg font-bold bg-dark2 flex items-center mb-4 rounded-sm"}>
                        <div className="w-10 md:w-16 pl-2"><img className="mx-auto w-6 " src={list} alt="Our Story" /></div>
                        <p className={styles.myBefore}>
                            OUR STORY
                        </p>
                    </div>
                </NavLink>
                {/* <div className="w-full border border-intenseBlue md:text-lg font-bold bg-dark2 flex items-center mb-4">
                    <div className="w-10 md:w-16 pl-2"><img className="mx-auto w-6 lg:w-1/2" src={settings} alt="Collection Mechanism" /></div>
                    <p className={styles.myBefore}>COLLECTION MECHANISM</p>
                </div> */}
                <NavLink to="/utility"> <div className={styles.shadoww + " w-full border border-intenseBlue md:text-lg font-bold bg-dark2 flex items-center mb-4 rounded-sm"}>
                    <div className={styles.utility + " w-10 md:w-16 pl-2"}><img className="mx-auto scale-75 w-6 " src={flash} alt="Utility" /></div>
                    <p className={styles.myBefore}>UTILITY</p>
                </div>
                </NavLink>
                <NavLink to="/our-team">
                    <div className={styles.shadoww + " w-full border border-intenseBlue md:text-lg font-bold bg-dark2 flex items-center mb-4 rounded-sm"}>
                        <div className="w-10 md:w-16 pl-2"><img className="mx-auto w-6  " src={blades} alt="Our Team" /></div>
                        <p className={styles.myBefore}>OUR TEAM</p>
                    </div>
                </NavLink>
                <NavLink to="/mint-details">
                    <div className={styles.shadoww + " w-full border border-intenseBlue md:text-lg font-bold bg-dark2 flex items-center mb-4 rounded-sm"}>
                        <div className="w-10 md:w-16 pl-2"><img className="mx-auto w-6 " src={settings} alt="Our Team" /></div>
                        <p className={styles.myBefore}>MINT DETAILS</p>
                    </div>
                </NavLink>
            </div>
        </div>
    );
}
