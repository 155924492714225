import React from 'react'
import styles from '../components/mintDetails/mintInfos.module.scss'
import MintInfos from '../components/mintDetails/MintInfos'
export default function MintDetails() {
    return (
        <div>
            <h1 className={styles.titleUnderline + ' ' + "text-intenseBlue   text-header   my-2  md:my-8 text-xl md:text-2xl  2xl:text-4xl md:mt-0 font-aviano"}>MINT DETAILS</h1>
            <MintInfos />

        </div>
    )
}
