import "./App.scss";

import ReactDOM from "react-dom/client";
import {

  Route,

  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home";
import Error from "./pages/Error";
import Contact from "./pages/Contact";
import Layout from "./pages/Layout";
import Ourwork from "./pages/Ourwork";
import Resources from "./pages/Resources";
import SneakPeeks from "./pages/sneakPeeks";
import OurStory from "./pages/OurStory";
import Utility from "./pages/Utility";
import OurTeam from "./pages/OurTeam";
import Hero from "./pages/Hero";
import { VideoProvider } from "./state/videoContext";
import MintDetails from "./pages/MintDetails";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Hero />} />
      <Route path="contact" element={<Contact />} />
      <Route path="resources" element={<Home />} />
      <Route path="our-work" element={<Ourwork />} />
      <Route path="/sneak-peeks" element={<SneakPeeks />} />
      <Route path="/our-story" element={<OurStory />} />
      <Route path="/utility" element={<Utility />} />
      <Route path="/our-team" element={<OurTeam />} />
      <Route path="/mint-details" element={<MintDetails />} />
      <Route path="/*" element={<Error />} />
    </Route>
  )
);





function App() {
  return (
    <div className="safe pt-safe-area pb-safe-area">

      <VideoProvider>
        <RouterProvider router={router} />
      </VideoProvider>
    </div>
  );
}

export default App;
