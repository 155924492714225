import React from 'react'
import Header from '../components/mainPage/header'
import container from '../assets/mainPage/container.png'
import wrapper from '../assets/mainPage/bgwrap3.svg'
import styles from './layout.module.scss'
export default function Home() {

  return (
    <div >


      <div className=' mx-auto  '>
        <div className='relative  ' >
          <Header />


          {/* <div className={styles.containersPoz}><img src={container} /></div> */}

        </div>

      </div>
    </div>
  )
}
