import React from 'react';
import ReactDOM from 'react-dom';
import styles from './nftModal.module.scss';
import legendary from '../../assets/secondPage/legendary.svg'
import background from '../../assets/secondPage/background.svg'
import skin from '../../assets/secondPage/skin.svg'
import hair from '../../assets/secondPage/hair.svg'
import clothing from '../../assets/secondPage/clothing.svg'
import mouth from '../../assets/secondPage/mouth.svg'
import nose from '../../assets/secondPage/nose.svg'
import eye from '../../assets/secondPage/eye.svg'
import ears from '../../assets/secondPage/ear.svg'
const Backdrop = (props) => {

    return (
        <div className={styles.backdrop} onClick={props.onClose} />);
};

const ModalOverlay = (props) => {
    return (
        <div>
            <div className={styles.modal}>
                <div className='border border-intenseBlue  md:border-none rounded-sm'>
                    <div className={styles.boxx + ' relative'}>
                        <button className={styles.closeBtn + ' md:hidden'} onClick={props.onClose}>X</button>
                        <div className={styles.imageWR}><img className={styles.imgSelf} src={props.nft.imageURL} /></div>
                        <div className={styles.absBox}>
                            <p className={styles.font_title}> {props.nft.name.toUpperCase()} Metalancer</p>
                        </div>
                        <div className={styles.infos}>
                            <div className={styles.infoBar}>
                                <div className={styles.imageDiv}><img className='object-cover' src={legendary} /></div>
                                <div className={styles.checkInfo}>
                                    <p className={styles.fontt}>Legendary</p>
                                    <p className='text-right font-semibold'>{(props.nft.legendary.toUpperCase())}</p>

                                </div>
                            </div>
                            <div className={styles.infoBar}>
                                <div className={styles.imageDiv}><img src={background} /></div>
                                <div className={styles.checkInfo}>
                                    <p className={styles.fontt}>BACKGROUND</p>
                                    <p className='text-right font-semibold'>{props.nft.background.toUpperCase()}</p>
                                </div>
                            </div>
                            <div className={styles.infoBar}>
                                <div className={styles.imageDiv}><img src={skin} /></div>
                                <div className={styles.checkInfo}>
                                    <p className={styles.fontt}>SKIN</p>
                                    <p className='text-right font-semibold'>{props.nft.skin.toUpperCase()}</p>
                                </div>
                            </div>
                            <div className={styles.infoBar}>
                                <div className={styles.imageDiv}><img src={hair} /></div>
                                <div className={styles.checkInfo}>
                                    <p className={styles.fontt}>HAIR</p>
                                    <p className='text-right font-semibold'>{props.nft.hair.toUpperCase()}</p>
                                </div>
                            </div>
                            <div className={styles.infoBar}>
                                <div className={styles.imageDiv}><img src={clothing} /></div>
                                <div className={styles.checkInfo}>
                                    <p className={styles.fontt}>CLOTHING</p>
                                    <p className='text-right font-semibold'>{props.nft.clothing.toUpperCase()}</p>
                                </div>
                            </div>
                            <div className={styles.infoBar}>
                                <div className={styles.imageDiv}><img src={mouth} /></div>
                                <div className={styles.checkInfo}>
                                    <p className={styles.fontt}>MOUTH</p>
                                    <p className='text-right font-semibold'>{props.nft.mouth.toUpperCase()}</p>
                                </div>
                            </div>
                            <div className={styles.infoBar}>
                                <div className={styles.imageDiv}><img src={nose} /></div>
                                <div className={styles.checkInfo}>
                                    <p className={styles.fontt}>NOSE</p>
                                    <p className='text-right font-semibold'>{props.nft.nose.toUpperCase()}</p>
                                </div>
                            </div>
                            <div className={styles.infoBar}>
                                <div className={styles.imageDiv}><img src={eye} /></div>
                                <div className={styles.checkInfo}>
                                    <p className={styles.fontt}>EYES</p>
                                    <p className='text-right font-semibold'>{props.nft.eyes.toUpperCase()}</p>
                                </div>
                            </div>
                            <div className={styles.infoBar}>
                                <div className={styles.imageDiv}><img src={ears} /></div>
                                <div className={styles.checkInfo}>
                                    <p className={styles.fontt}>EARS</p>
                                    <p className='text-right font-semibold'>{props.nft.ears.toUpperCase()}</p>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </div>





        // <div className={styles.modal}>
        //     <header className={styles.header}>
        //         <h2>{props.nft.name}</h2>
        //     </header>
        //     <div className={styles.content}>
        //         <p>{props.nft.message}</p>
        //     </div>
        //     <footer className={styles.actions}>
        //         <button onClick={props.onClose}>Okay</button>
        //     </footer>
        // </div>
    );
};

export default function NftModal(props) {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop onClose={props.onClose} />,
                document.getElementById('backdrop-root')
            )}
            {ReactDOM.createPortal(
                <ModalOverlay onClose={props.onClose} nft={props.nft} />,
                document.getElementById('overlay-root')
            )}
        </React.Fragment>
    );
}
