import React from 'react'
import nts from '../assets/mainPage/NOTHINGTOSEE.png'
import persons from '../assets/ourStory/persons.svg'
import { NavLink } from "react-router-dom";
export default function Error() {
  return (
    <div className='w-full '>
      <div className='mx-auto text-center mt-[15%] md:mt-0 '>
        <div className='w-full  mx-auto mb-[90px]  md:mb-8'><img className='mx-auto w-[80%] md:w-1/4' src={nts} /></div>
        <div className='w-full mx-auto'><img className=' mx-auto max-[768px]:scale-150 scale-075 min-[1600px]:scale-125' src={persons} /></div>
        <NavLink className=' bg-intenseBlue rounded-sm py-2 px-4 md:py-4 md:px-8' to='/resources'><button className=' mt-6 min-[1600px]:mt-[100px]'>TAKE ME HOME</button></NavLink>

      </div>
    </div>
  )
}
