import React from 'react'
import metaZ from '../assets/secondPage/metalancerZ.svg'
import wrapBG from '../assets/secondPage/wrapbg.png'
import NftBox from '../components/secondPage/nftBox';
import nft1 from '../assets/secondPage/nft1.png'
import nft2 from '../assets/secondPage/nft2.png'
import nft3 from '../assets/secondPage/nft3.png'
import nft4 from '../assets/secondPage/nft4.png'
import nft5 from '../assets/secondPage/nft5.png'
import nft6 from '../assets/secondPage/nft6.png'
import nft7 from '../assets/secondPage/nft7.png'
import nft8 from '../assets/secondPage/nft8.png'
import nft9 from '../assets/secondPage/nft9.png'
import nft10 from '../assets/secondPage/nft10.png'
import nft11 from '../assets/secondPage/nft11.png'
import nft12 from '../assets/secondPage/nft12.png';
import styles from '../components/secondPage/nftBox.module.scss'
import pdf from '../assets/video/Creative_Journey_Full.pdf'

export default function SneakPeeks() {

  const backgroundImageStyle = {
    backgroundImage: `url(${wrapBG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const allNFT = [
    {
      id: 1,
      name: 'terra nava',
      legendary: 'none',
      background: 'Gravity Pull',
      skin: "Tigrisar Skin",
      hair: 'Green Crop Cut',
      clothing: 'Junkyard Fighter',
      mouth: 'Chapped Lip',
      nose: 'Golden Ribs',
      eyes: 'Red Scrap Shades',
      ears: 'Blue Thorns',
      imageURL: nft1
    },
    {
      id: 2,
      name: 'Nero',
      legendary: 'none',
      background: 'Brown Wanted',
      skin: "Nero Skin",
      hair: 'Brown Gelu',
      clothing: 'Red Fleet Shadow',
      mouth: 'E-Smoke',
      nose: 'Blue Combat Shades',
      eyes: 'Code Melt',
      ears: 'Pierced Noob',
      imageURL: nft2
    },
    {
      id: 3,
      name: 'terra nava',
      legendary: 'none',
      background: 'Junkyard',
      skin: "Terra Nava Skin",
      hair: 'Euphoric Glam',
      clothing: 'Silver MetaGuard',
      mouth: 'Thunder Balloon Pierce',
      nose: 'Keep It Simple',
      eyes: 'Laurel Eyes',
      ears: 'Kling On',
      imageURL: nft3
    },
    {
      id: 4,
      name: 'valezard',
      legendary: 'none',
      background: 'Nero Skies',
      skin: "Valezard Skin",
      hair: 'Neon Flames Cut',
      clothing: 'Jax Bionic Shoulder',
      mouth: 'Cuban Flavor',
      nose: 'Golden Ribs',
      eyes: 'Blue Scrap Shades',
      ears: 'Blue Thorns',
      imageURL: nft4
    },
    {
      id: 5,
      name: 'Tigrisar Sanctuary',
      legendary: 'none',
      background: 'Bagratuni Mountains',
      skin: "Tigrisar Skin",
      hair: 'Forest Green Bangs',
      clothing: 'Smugler Queen',
      mouth: 'Pink Balloon Pierce',
      nose: 'Golden Septum Stardust',
      eyes: 'Cat Eye Shades',
      ears: 'Silver Thunder Headphones',
      imageURL: nft5
    },
    {
      id: 6,
      name: 'terra nava',
      legendary: 'none',
      background: 'Valezard Valley',
      skin: "Terra Nava III Skin",
      hair: 'Serpent Cut',
      clothing: 'Captain on Deck Suit',
      mouth: 'Goatee Stubble',
      nose: 'Golden Ribs ',
      eyes: 'Inked',
      ears: 'Orb Ring',
      imageURL: nft6
    },
    {
      id: 7,
      name: 'Nairi',
      legendary: 'none',
      background: 'Brown Wanted',
      skin: "Nairi Skin",
      hair: 'Thorn Warrior',
      clothing: 'Green Galactic Swag',
      mouth: 'To The Moon',
      nose: 'Silver Thunder',
      eyes: 'Rosalia',
      ears: 'Golden Stardustg',
      imageURL: nft7
    },
    {
      id: 8,
      name: 'Tigrisar Sanctuary',
      legendary: 'none',
      background: 'Dunes at Dawn',
      skin: "Tigrisar Skin",
      hair: 'Pink Champagne Cut ',
      clothing: 'Nairi Armour',
      mouth: 'Ornamental Bloom',
      nose: 'Kling On Spike',
      eyes: 'Red Warrior',
      ears: 'Blue Gamer Headphones',
      imageURL: nft8
    },
    {
      id: 9,
      name: 'Tethra',
      legendary: 'none',
      background: 'Nero Dawn',
      skin: "Tethra Skin ",
      hair: 'Silver Laurel Wreath',
      clothing: 'Blue Wing It ',
      mouth: 'Blue Murano Pipe',
      nose: 'Celtic Knot',
      eyes: 'Golden Thread',
      ears: 'Punk Ear Shield',
      imageURL: nft9
    },
    {
      id: 10,
      name: 'Orb II',
      legendary: 'Golden Shield',
      background: 'The Great Orb II',
      skin: "Orb II Skin",
      hair: 'Tethra Royal Cut ',
      clothing: 'Teal Terra Nava Pilot',
      mouth: 'Chapped Lip',
      nose: 'Teal Septum Star',
      eyes: 'Zard Curse ',
      ears: 'Black Supersonic Amplifier',
      imageURL: nft10
    },
    {
      id: 11,
      name: 'terra nava',
      legendary: 'none',
      background: 'Magenta Haze',
      skin: "Terra Nava Skin",
      hair: 'Silver Laurel Wreath',
      clothing: 'Ambassador Fang',
      mouth: 'Pop Tart',
      nose: 'Double Pierce',
      eyes: 'Silver Shield Shades',
      ears: 'Nairi Rings',
      imageURL: nft11
    },
    {
      id: 12,
      name: 'Foresta Tenebran',
      legendary: 'none',
      background: 'Junkyard Graffiti',
      skin: "Foresta Tenebran Skin",
      hair: 'purple Dreads',
      clothing: 'White Fold Dress ',
      mouth: 'Mad Pipe',
      nose: 'Kling On Spike',
      eyes: 'Ajna',
      ears: ' Golden Stardust',
      imageURL: nft12
    },

  ];


  return (
    <div className='lg:flex  gap-[50px] '>
      <div className='w-full  lg:w-[42%] mb-4 sm:mb-2xl lg:mb-0'>
        <h1 className={styles.titleUnderline + ' ' + " whitespace-nowrap text-intenseBlue   text-header   my-2  md:my-8 text-xl md:text-2xl  2xl:text-4xl md:mt-0 font-aviano"}>SNEAK PEEKS</h1>
        <p className='pb-4 xl:w-3/4 text-xxs md:text-base'>Gain valuable insights into the inspiration, techniques,
          and themes behind the artwork. Immerse yourself in
          this mesmerizing journey through the preview gallery
          and experience the captivating world of Metalancer,
          where artistry meets innovation </p>

      </div>
      <div className='w-full  my-2xl lg:w-3/5 lg:h-screen  lg:overflow-y-scroll lg:scrollbar-hide'>
        <a href={pdf} target='_black'>
          <div style={backgroundImageStyle} className='relative h-[255px] lg:h-[50%] lg:h-[427px] border border-intenseBlue shadow-3xl rounded-sm'>
            <div className='w-full  mx-auto text-center' >
              <img className='mx-auto mt-6 lg:pt-[7%]  h-[129px] lg:h-full lg:w-[405px] ' src={metaZ} />
              <button className={styles.ctaHeader}> A CREATIVE ODYSEY</button><br></br>
              <button className={styles.ctaHeaderBlue}> Discover now</button>

            </div>
          </div>
        </a>
        <div>
          <div className=' my-xl flex justify-items-stretch gap-4 lg:gap-xl flex-wrap'>
            {allNFT.map((nft) => {
              return (
                <NftBox key={nft.id} nft={nft} img={nft.imageURL} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
