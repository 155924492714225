import React, { useState } from 'react';
import NftModal from './nftModal';
import styles from './nftBox.module.scss'


export default function NftBox(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    let title = props.nft.name.split(" ")[0];
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className='mt-4 lg:mt-0  grow strech   w-1/3 lg:w-1/5' >
            <div onClick={openModal}>
                <div className='flex-stretch w-full grow'>
                    <img className='object-cover rounded-sm' src={props.img} alt={props.nft.name} />
                </div>
                <div className={styles.titleContainer + ' rounded-b-sm'}>
                    <p className={styles.title}>{title}</p>
                </div>
            </div>
            {isModalOpen && <NftModal onClose={closeModal} nft={props.nft} />}
        </div>
    );
}
