import React from 'react'
import styles from '../components/ourStory/collapsable.module.scss'
import Collapsible from '../components/ourStory/collapsable'
export default function OurStory() {




    return (
        <div>
            <div className=''>
                <h1 className={styles.titleUnderline + ' ' + "text-intenseBlue   text-header   my-2  md:my-8 text-xl md:text-2xl  2xl:text-4xl md:mt-0 font-aviano"}>OUR STORY</h1>
                <Collapsible />
            </div>
        </div>
    )
}
