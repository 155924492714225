import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/fonts/avianofuture.ttf'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(


  <div className='bg-silver'>
    <App />
  </div>

);

reportWebVitals();
