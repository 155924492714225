import React from 'react'

export default function Contact() {
  return (
    <div className='container mx-auto'>
      <div className='container bg-black text-silver rounded-xl'>
        <h2 className='text-l py-4  md:text-xl lg:text-2xl'>30- Minute Discovery Call</h2>
        <p className='pb-4'>Take advantage of our complimentary <span className='text-orange'>30-minute consultation</span> to gain insight into your business and objectives. </p>
        <p className='pb-4'>Our expert team will provide you with a valuable plan to help you achieve success in your venture.</p>
      </div>
    </div>
  )
}
