import React, { useState, useEffect } from 'react';
import styles from './hero.module.scss'
import discord from '../../assets/mainPage/discordLogo.svg';
import insta from '../../assets/mainPage/instaLogo.svg';
import instaMobile from '../../assets/mainPage/instagram.svg'
import twitter from '../../assets/mainPage/twitterLogo.svg';
import openSea from '../../assets/mainPage/opensea.svg'
import mute from '../../assets/mainPage/soundMute.svg';
import play from '../../assets/mainPage/soundPlay.svg'
import discordMobile from '../../assets/mainPage/discord.svg';
import twitterMobile from '../../assets/mainPage/twitter.svg'
import { useVideo } from '../../state/videoContext';
import logoBig from '../../assets/mainPage/two_row_logo_metalancer-02.svg'
const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(undefined);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowWidth;
}



export default function HeroComponent() {
    const { toggleMute } = useVideo();
    const [isMuted, setIsMuted] = useState(true);
    const windowWidth = useWindowWidth();

    const handleSoundToggle = () => {
        setIsMuted(!isMuted);
        toggleMute()
    };



    return (
        <div className='h-[calc(100vh-95px)] md:h-full'>

            <div className={styles.infos}>

                <div className={styles.shares}>
                    <div>
                        <a href="https://discord.com/invite/deh2ykmR3e" target="_blank">
                            <img src={windowWidth < 768 ? discordMobile : discord} />
                        </a>
                    </div>
                    <div>
                        <a href="https://www.instagram.com/metalancerhq/" target="_blank">
                            <img src={windowWidth < 768 ? instaMobile : insta} />
                        </a>
                    </div>
                    <div>
                        <a href="https://twitter.com/metalancerHQ" target="_blank">
                            <img src={windowWidth < 768 ? twitterMobile : twitter} />
                        </a>
                    </div>
                </div>
                <div className={styles.comingSoon}>
                    <p className='whitespace-nowrap'>COMING SOON ON </p>
                    <div><img src={openSea} /></div>
                </div>
                {window.innerWidth < 768 && <div className='w-screen'>
                    <img className='w-[82%] ml-auto' src={logoBig} />
                </div>}
            </div>
            <div onClick={handleSoundToggle} className={styles.soundBtn}>
                <img src={isMuted ? mute : play} />
            </div>
        </div>
    )
}
