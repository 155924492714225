import React from 'react'
import styles from './memeberBox.module.scss'
import nft from '../../assets/team/christian.png'
import linkedinLogo from '../../assets/team/linkedinLogo.png'
import topLine from '../../assets/team/underlineTop.svg';
import bottomLine from '../../assets/team/underlineBottom.svg';

export default function MemberBox(props) {




    return (
        <div className={styles.box}>
            <div className={styles.imgWrapper}> <img src={props.img} /></div>

            <div className={styles.infos}>
                <div className='md:min-h-[60px] lg:mt-4 md:flex flex-col items-center justify-center '>
                    <div className={styles.topLine + ' hidden md:block'}>
                        <img src={topLine} />
                    </div>

                    <p className={styles.name + ' xl:py-1'}>{props.name}</p>
                    <div className={styles.bottomLine}>
                        <img src={bottomLine} />
                    </div>
                </div>

                <p className={styles.role}>{props.role}</p>

                {props.linkedin && <a className={styles.logoWr} target='_blank' href={`${props.linkedin}`}><img alt="LinkedIn Logo" src={linkedinLogo} /></a>}
            </div>
        </div>
    )
}
