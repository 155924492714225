import React from 'react';
import myApps from '../assets/utilityPage/ourApps.png'
import img1 from '../assets/utilityPage/cards.png'
import img2 from '../assets/utilityPage/card2.png'
import styles from './utility.module.scss'
export default function Utility() {
    return (
        <div className=''>
            <h1 className={styles.titleUnderline + ' text-intenseBlue underline-offset-7  text-header my-2  md:my-8 text-xl md:text-2xl  2xl:text-4xl md:mt-0 font-aviano'}>UTILITY</h1>
            <div className='mb-xl text-xxs md:text-18px '>
                <p className='font-semibold'>We are all very, very early. Leaving the technical mumbo-jumbo behind - what is an NFT?
                    <br></br>    No - not what the acronym stands for. What is it actually?</p>
                <p className='py-4 font-light'>To us, NFTs are digital collectibles that you truly own - unlike digital items locked in proprietary ecosystems. The majority of people have no idea what NFTs are, nor do they care
                    to find out. And why would they? In every area of our life, the tech powering day-to-day activities is hidden away. Nobody thinks or cares about operating system their washing
                    machine uses, or how their espresso coffee machine detects what pod has been inserted. In order for a new technology to be universally adopted by consumers, it must be
                    entirely seamless.</p>
                <p>NFTs have the power to completely revolutionise the world of comic books and online comic creators. If you are not familiar with the power of their communities and their love
                    for collectibles - where even were you in the last 20 years? Revenue has gone through the roof, with more and more people buying collectibles and reading comics. However, the
                    majority of readers have left behind paper comic books - in favour of mobile apps.</p>
            </div>
            <div className='bg-intenseBlue rounded-sm md:rounded-xl text-xxs md:text-18px lg:p-xl 2xl:px-2xl mb-xl'>
                <div className='flex flex-col lg:flex-row gap-4 p-6 items-center'>
                    <div className={styles.leftPanel + ' flex flex-col gap-4 flex-1'}>
                        <h2 className='text-18px font-bold font-aviano lg:text-xl leading-none  2xl:text-header '>WEB3 COMICS APP</h2>
                        <p>We are building a comic book reading mobile app. A true mobile app, not a clunky
                            website that you can only access through Metamask. The app serves 2 functions:</p>
                        <div >
                            <div className={styles.paraBorder + ' pl-4  mb-4 font-semibold'}> <p >Users can read comic books (scroll-based) and web-novels. Each item they want to read is sent to a custodial wallet as a digital collectible (NFT).</p></div>
                            <div className={styles.paraBorder + ' pl-4 font-semibold'}><p >Content creators can upload their content onto the platform, for readers to enjoy. There are significant financial incentives to do this, with rates well over the industry standard.</p></div>

                        </div>
                        <p className='hidden lg:block'>Why are we building the app? We want to deliver an awesome reading experience and to
                            put the power back into the readers hands • by actually letting you own the content you
                            read and properly supporting the creators that provide that content</p>
                        <p className='hidden lg:block'>As we get closer to mint, more details about the app will be released.</p>
                    </div>
                    <div className='flex-1'>
                        <img className={styles.myAppsImg} src={myApps} />
                    </div>

                    <p className='lg:hidden'>Why are we building the app? We want to deliver an awesome reading experience and to
                        put the power back into the readers hands • by actually letting you own the content you
                        read and properly supporting the creators that provide that content</p>
                    <p className='lg:hidden'>As we get closer to mint, more details about the app will be released.</p>
                </div>
            </div>
            <div className='bg-intenseBlue rounded-sm md:rounded-xl text-xxs md:text-18px lg:p-xl 2xl:px-2xl mb-xl'>
                <div className='flex flex-col lg:flex-row justify-between gap-4 p-6 items-center'>
                    <div className={styles.leftPanel + ' flex flex-col gap-4 flex-1'}>

                        <h2 className=' text-18px font-bold font-aviano lg:text-xl  2xl:text-header leading-none  z-2'>NFT Licensing Platform</h2>
                        <p className='whitespace-normal'>The IP behind certain NFTs is a valuable asset. The current solutions regarding NFT
                            licensing are minimal, which leads to several market failures:</p>
                        <div className='whitespace-normal'>
                            <div >
                                <p className={styles.paraBorder + ' pl-4  mb-4 font-semibold'}>It is difficult for a potential licensee to find a licensor with the appropriate IP, leading to less licensing deals being conducted.</p>
                            </div>
                            <div >
                                <p className={styles.paraBorder + ' pl-4 font-semibold'}>Since no standard exists for this type of deal, scams are abundant. Even for experiences users, it is difficult to distinguish a legitimate offer from a fraudulent attempt. This leads to less potential licensors who are willing to license their NFT’s IP.</p>
                            </div>


                        </div>
                        <p >Despite significant demand, there has been little effort made to design a standardised
                            process and platform to facilitate NFT licensing</p>

                    </div>
                    <div className='flex-1 z-1'>
                        <img className={styles.cardsImg} src={img1} />
                    </div>
                </div>
            </div>
        </div>
    )
}
