import React, { useEffect, useState } from "react";

import { NavLink, Outlet } from "react-router-dom";
import { Disclosure, isOpen } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import LogoM from '../assets/mainPage/full_logo.svg'
import LogoMeta from '../assets/mainPage/logoMeta.png'
import bgWrapper from '../assets/mainPage/bgwrap3.svg'
import { useLocation } from 'react-router-dom';
import insta from '../assets/mainPage/instagram.svg';
import discord from '../assets/mainPage/discord.svg';
import twitter from '../assets/mainPage/twitter.svg';
import telegram from '../assets/mainPage/telegram.svg'
import styles from './layout.module.scss'
import bgNav from '../assets/mainPage/bgnavbar.png'
import mintBG from '../assets/mainPage/mint_image.png'
import heroVideo from '../assets/video/herobanner.mp4'
import heroVideoMobile from '../assets/video/heroBannerMobile.mp4'
import { useVideo } from "../state/videoContext";
import container from '../assets/mainPage/container.png'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function Layout() {

  const location = useLocation();

  let backgroundImageUrl = '';
  const isSharesVisible = location.pathname === '/resources' || location.pathname === '/' || (location.pathname === '/our-team' && window.innerWidth < 1025);
  const switchNavMint = location.pathname === '/' || location.pathname === '/our-work';
  const hideBorder = location.pathname === '/' && window.innerWidth > 768;
  const isOnHomePage = location.pathname === '/';
  const isOnResources = location.pathname === '/resources';
  const [navbarOpen, setNavbarOpen] = useState(false);
  const { isMuted } = useVideo();

  if (location.pathname === '/resources' || location.pathname === '/mint-details') {
    backgroundImageUrl = bgWrapper;
  } else if (location.pathname === '/our-work' && !navbarOpen) {
    backgroundImageUrl = mintBG;
  }


  const backgroundImageStyle = {
    backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : '',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const backgroundImageNav = {
    backgroundImage: `url(${bgNav})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'left',
  };

  useEffect(() => {
    setNavbarOpen(navbarOpen)
  }, [navbarOpen])

  return (
    <div className="p-2 md:p-xl overflow-hidden bg-dark1 " style={backgroundImageStyle}>
      {isOnHomePage && !navbarOpen &&
        <video className="pointer-events-none" autoPlay playsInline loop muted={isMuted} id={styles.myVideo}>
          <source src={window.innerWidth > 768 ? heroVideo : heroVideoMobile} type="video/mp4" />
        </video>}
      <div className={`relative  ${isSharesVisible ? "" : "overflow-hidden"}`}>
        {!isSharesVisible &&
          <div>
            <div className={styles.shares}>
              <div><a target="_blank" href="https://www.instagram.com/metalancerhq/"><img src={insta} /></a></div>
              <div><a target="_blank" href="https://discord.com/invite/deh2ykmR3e"><img src={discord} /></a> </div>
              <div><a target="_blank" href="https://twitter.com/metalancerHQ" ><img src={twitter} /></a> </div>

            </div>
            <div className={styles.borderHidden}></div>
          </div>}
        <div className={`${!hideBorder ? 'border border-intenseBlue rounded-sm' : ''}  lg:overflow-y-scroll lg:overflow-x-hidden  min-h-[calc(100vh-15px)] md:min-h-[calc(100vh-66px)]   lg:max-h-[calc(100vh-72px)] lg:scrollbar-hide`}  >

          <div className=" mx-auto" >
            <Disclosure as="nav" className={`z-60  mx-auto `} style={navbarOpen ? backgroundImageNav : null} >
              {({ open }) => (


                <>
                  <div className={` mx-auto p-4  xL:pt-l xl:pl-l `} >
                    <div className="flex  w-full">
                      <div className="flex justify-between w-full items-center">
                        <div className="flex flex-shrink-0 items-end">
                          <NavLink to="/">

                            <div className="flex items-center">
                              {window.innerWidth < 640 && <Disclosure.Button onClick={() => {
                                if (navbarOpen) {
                                  setNavbarOpen(!navbarOpen);
                                }
                              }} className={`${!navbarOpen ? 'pointer-events-none' : 'pointer-events-auto'}`}>
                                <img
                                  className={`h-[19px] w-[168px] md:h-[27px] md:w-[236px] min-[1700px]:h-[40px] min-[1700px]:w-[300px]`}
                                  src={LogoM}
                                  alt="Your Company"

                                />
                              </Disclosure.Button>}
                              {window.innerWidth > 640 && <img
                                className="h-[19px] w-[168px] md:h-[27px] md:w-[236px]  min-[1700px]:h-[40px] min-[1700px]:w-[300px]"
                                src={LogoM}
                                alt="Your Company"

                              />}
                            </div>


                          </NavLink>
                        </div>
                        <div className="  text-white flex flex-wrap hidden sm:ml-6 sm:flex md:space-x-2 lg:space-x-8 h-8 pr-lg">
                          {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}

                          {/* <NavLink
                            to="/resources"
                            className="text-white inline-flex items-center border-b-2 border-transparent px-2  pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                          >
                            RESOURCES
                          </NavLink> */}
                          <NavLink
                            to="/resources"
                            className={styles.shadoww + " text-white inline-flex  items-center rounded my-auto border-2 border-intenseBlue px-2 lg:px-12  text-sm font-medium bg-dark2 p-1 text-gray-500 hover:border-gray-300 hover:text-gray-700"}
                          >
                            RESOURCES
                            {/* {!switchNavMint ? 'MINT NOW' : 'CONNECT WALLET'} */}
                          </NavLink>
                          {/* <NavLink
                          to="/insights"
                          className="text-white inline-flex items-center border-b-2 border-transparent px-2 lg:px-8 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        >
                          INSIGHTS
                        </NavLink> */}

                        </div>
                      </div>

                      <div id="mobileMenu" onClick={() => {
                        setNavbarOpen(!navbarOpen)
                      }} className=" -mr-2 flex items-center sm:hidden ">
                        {/* Mobile menu button */}
                        <Disclosure.Button id="BAR_BTN" className=" transition-all inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XMarkIcon className="block text-white h-6 w-6" aria-hidden="true" />
                          ) : (
                            <Bars3Icon className="block text-white h-6 w-6" aria-hidden="true" />
                          )}
                        </Disclosure.Button>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className=" overflow-hidden border-b border-intenseBlue bg-transparent transition-transform ease-linear h-[calc(100vh-87px)] w-full delay-1000 duration-1000 sm:hidden bg-black flex flex-col items-center justify-center">
                    <div className="flex flex-col justify-center h-[calc(100vh-56px)] ">
                      <div className="flex flex-col items-center space-y-4  h-full">
                        <NavLink
                          to="/resources"
                          className="flex items-center my-auto font-aviano font-semibold justify-center  bg-indigo-50 py-2 text-xl   text-base font-medium text-white"
                        >
                          <Disclosure.Button onClick={() => { setNavbarOpen(!navbarOpen) }}>RESOURCES</Disclosure.Button>

                        </NavLink>
                        {/* <NavLink
                          to="/our-work"
                          className="font-bold flex items-center justify-center text-xl  border-transparent py-2  text-base  text-white hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                        >
                          MINT NOW
                        </NavLink> */}

                      </div>
                      <div className="flex  flex-col items-center mb-[-1px]  mt-auto relative ">

                        <div className={styles.sharesNav}>
                          <div><a target="_blank" href="https://twitter.com/metalancerHQ"><img src={twitter} /></a></div>
                          <div><a target="_blank" href="https://www.instagram.com/metalancerhq/"><img src={insta} /></a> </div>
                          <div><a target="_blank" href="https://discord.com/invite/deh2ykmR3e"><img className={styles.discordLogo} src={discord} /></a> </div>


                        </div>
                        <div className={styles.wrapperr}><div className={styles.borderHiddenNav}></div></div>


                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          {!navbarOpen && <div className="px-4 lg:px-4xl 2xl:px-bigCont  mx-auto  2xl:mt-xl text-white ">
            <div><Outlet />
              {isOnResources && <div className={styles.containersPoz}><img src={container} /></div>}
            </div>

          </div>}
        </div>
      </div>
    </div>
  );
}
