import React from 'react'
import styles from './mintInfos.module.scss'
import ethLogo from '../../assets/mainPage/ethLogo.svg'
import comSoon from '../../assets/mainPage/comSoon.png'
export default function MintInfos() {
    return (
        <div className='my-12'>
            <div className='w-full flex flex-strech flex-wrap font-avianoFast gap-[20px] lg:gap-[60px]'>
                <div className={styles.containerBG + ' w-full border    border-violet rounded-xs md:rounded-[20px] min-[1700px]:rounded-xl bg-[#0b0c35]'} >
                    <div className=' flex items-center  max-md:flex-wrap p-4 xl:p-8 min-[1700px]:py-[51px] min-[1700px]:px-[61px] '>
                        <div className='max-[411px]:text-xs mr-4 lg:mr-0 text-18px xl:text-xl  min-[1700px]:text-39px max-[372px]:pr-4 pr-8 lg:pr-[51px min-[327px]:border-r-2 max-[327px]:border-b-2 border-violet'>
                            <p>Date Tba</p>
                            <p className='text-violet'>PRESALE</p>
                        </div>
                        <div className='pr-4  max-[411px]:text-xs text-18px xl:text-xl  min-[1700px]:text-39px lg:px-[30px] '>
                            <p className='flex items-baseline'>1000 <span className=' ml-2 text-xxs lg:text-18px'>MINTS</span></p>
                            <p className='flex'>0.05 <img className='w-[8px] lg:w-[17px] ml-2 lg:ml-4' src={ethLogo} /></p>
                        </div>
                        <div className={styles.imageBG + ' '}>
                            {/* <p>Everyone who is currently whitelisted will be able to buy a raffle ticket for the presale.</p>
                            <p className='min-[1700px]:py-4'>If you are not successful, we will refund you.</p>
                            <p>Those who have the Treaty Protector role are guaranteed a spot.</p> */}
                            <p>COMING SOON</p>
                        </div>

                    </div>
                </div>
                <div className={styles.containerBG + ' bg-[#0b0c35] w-full lg:w-[calc(50%-30px)] border border-violet rounded-xs md:rounded-[20px] min-[1700px]:rounded-xl max-[411px]:text-sm text-18px xl:text-xl  min-[1700px]:text-39px'}>
                    <div className='flex items-center p-4 pr-0 xl:py-8 xl:pl-8  min-[1700px]:py-[51px] min-[1700px]:pl-[61px]'>
                        <div className='max-[411px]:text-xs max-[372px]:pr-4 pr-4 lg:pr-[41px] border-r-2 border-violet'>
                            <p>Date Tba</p>
                            <p className='text-violet max-[327px]:whitespace-normal whitespace-nowrap'>PRIVATE SALE</p>
                        </div>
                        <div className='max-[411px]:text-xs pl-4 lg:pl-[31px]'>
                            <p className=''>6500 <span className='text-xxs lg:text-18px'>MINTS</span></p>
                            <p className='flex'>0.07<img className='w-[8px] lg:w-[17px] ml-2 lg:ml-4' src={ethLogo} /></p>
                        </div>
                    </div>
                </div>
                <div className={styles.containerBG + ' bg-[#0b0c35] w-full lg:w-[calc(50%-30px)] border   border-violet rounded-xs md:rounded-[20px] min-[1700px]:rounded-xl max-[411px]:text-sm text-18px xl:text-xl  min-[1700px]:text-39px'}>
                    <div className='flex items-center  p-4 pr-0 xl:p-8  min-[1700px]:py-[51px] min-[1700px]:pl-[61px]'>
                        <div className='max-[411px]:text-xs max-[372px]:pr-6 pr-7 lg:pr-[41px] border-r-2 border-violet'>
                            <p>Date Tba</p>
                            <p className='text-violet max-[327px]:whitespace-normal whitespace-nowrap'>PUBLIC SALE</p>
                        </div>
                        <div className='max-[411px]:text-xs pl-4 lg:pl-[31px]'>
                            <p>1800 <span className='text-xxs lg:text-18px'>MINTS</span></p>
                            <p className='flex'>0.08<img className='w-[8px] lg:w-[17px] ml-2 lg:ml-4' src={ethLogo} /></p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
