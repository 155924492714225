import React from 'react'
import styles from '../components/ourTeam/memeberBox.module.scss'
import radu from '../assets/team/radu.png'
import elena from '../assets/team/elena.png'
import simina from '../assets/team/simina.png'
import victoria from '../assets/team/victoria.png'
import hrant from '../assets/team/hrant.png'
import christian from '../assets/team/christian.png'
import Tudor from '../assets/team/tudor.png'
import MemberBox from '../components/ourTeam/MemberBox'
import ionut from '../assets/team/Ionut.png'
import andrei from '../assets/team/chirea.png';
import jrny from '../assets/team/JRNY.png';
import woro from '../assets/team/Wordsmith.jpeg';
import alexandra from '../assets/team/Alexandra.jpg'

export default function OurTeam() {
    const allMemebers = [
        {
            id: 1,
            img: Tudor,
            name: 'Tudor Stomff',
            role: 'founder & chairman',
            linkedin: 'https://www.linkedin.com/in/tudor-stomff-a74771bb/'
        },
        {
            id: 2,
            img: christian,
            name: 'Christian alexandrescu',
            role: 'co founder & ceo',
            linkedin: 'https://www.linkedin.com/in/cristialx/'
        },
        {
            id: 3,
            img: jrny,
            name: 'jrny Crypto',
            role: 'co founder',
            linkedin: 'https://twitter.com/JRNYcrypto'
        }
        , {
            id: 4,
            img: alexandra,
            name: 'Alexandra Voiculescu',
            role: 'Ops Manager',
            linkedin: 'https://www.linkedin.com/in/alexandra-voiculescu-210799199/'
        },
        {
            id: 5,
            img: hrant,
            name: 'hrant jaghinyan',
            role: 'creative director',
            linkedin: 'https://www.linkedin.com/in/hrant-jaghinyan-a05b48112/'
        },
        {
            id: 6,
            img: victoria,
            name: 'victoria nazarian',
            role: 'senior designer',

        },
        {
            id: 7,
            img: simina,
            name: 'simina',
            role: 'illustrator',
            linkedin: 'https://www.siminapopescu.com/'
        },
        {
            id: 8,
            img: radu,
            name: 'radu moraru',
            role: 'developer',
            linkedin: 'https://www.linkedin.com/in/radu-moraru-20a17419b/'
        },
        {
            id: 9,
            img: elena,
            name: 'elena ciobanu',
            role: 'business development',
            linkedin: 'https://www.linkedin.com/in/elena-ciobanu-4a77971b1/'
        },
        {
            id: 10,
            img: woro,
            name: 'WORDSMITH',
            role: 'COPYWRITER',

        },
        {
            id: 11,
            img: andrei,
            name: 'ANDREI CHIREA',
            role: 'DEVELOPER',
            linkedin: 'https://www.linkedin.com/in/andrei-chirea-39b869210/'
        },
        {
            id: 12,
            img: ionut,
            name: 'Ionut Paicu',
            role: 'Developer',
            linkedin: 'https://www.linkedin.com/in/ionut-paicu-7371001b1/'
        }
    ]

    const content = allMemebers.map((membr) =>
        <MemberBox key={membr.id} img={membr.img} name={membr.name} role={membr.role} linkedin={membr.linkedin} />
    );


    return (
        <div className=' mb-8 lg:mb-16'>
            <h1 className={styles.titleUnderline + ' ' + "text-intenseBlue   text-header   my-2  md:my-8  text-xl md:text-2xl  2xl:text-4xl md:mt-0 font-aviano"}>OUR TEAM</h1>
            <div className={styles.containerMembr + ' flex flex-col gap-8 my-md  2xl:my-3xl'}>
                {content}
            </div>
        </div>
    )
}
