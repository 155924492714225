import React from 'react'
import HeroComponent from '../components/heroPage/Hero'

export default function Hero() {

    return (
        <div>
            <HeroComponent />
        </div>
    )
}
